#home {
  position: relative;
  background: url("../../assets/bgIMG.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  @media screen and (max-width: 1200px) {
    align-items: center;
  }
  .app__wrapper {
    padding: 0;
    .copyright {
      display: none;
    }
  }
}
.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: flex-start;
  padding: 6rem 2rem 0;
  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  @media screen and (max-width: 500px) {
    padding: 6rem 1rem 2rem;
  }
  .app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      width: 100%;
      margin-right: 0rem;
    }
  }
}
.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  padding-left: 1.3rem;
  .tags,
  .badge-cmp {
    padding: 1rem 2rem;
    border: var(--white-color);
    border-radius: 15px;
    flex-direction: row;
    width: auto;
    animation: 0.8s infinite alternate pulse;
    @keyframes pulse {
      from {
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
      }
      to {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.14);
      }
    }
  }
  h1 {
    font-family: sans-serif;
    font-weight: 900;
    line-height: 3.4rem;
    background: -webkit-linear-gradient(
      var(--secondary-color),
      var(--secondary-color),
      rgb(243, 17, 194)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .tags {
    flex-direction: column;
    margin-top: 2rem;
    p {
      text-transform: uppercase;
      width: 100%;
      text-align: right;
    }
  }
  span {
    font-size: 2.5rem;
    animation: 0.55s infinite alternate slidein;
    backface-visibility: hidden;
    @keyframes slidein {
      from {
        transform: rotate(-25deg);
      }

      to {
        transform: rotate(45deg);
      }
    }
    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }
  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.app__header-circle {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
  margin-left: 1rem;
  @media screen and (max-width: 1200px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    img {
      width: 80%;
      height: 80%;
      object-fit: contain;
    }
    @media screen and (max-width: 900px) {
      margin-top: 10px;
    }
    &:nth-child(1) {
      width: 72px;
      height: 72px;
      margin-right: 2rem;
    }
    &:nth-child(2) {
      width: 60px;
      height: 60px;
      margin-left: 3rem;
    }
    &:nth-child(3) {
      margin-right: 3rem;
    }
    &:nth-child(4) {
      width: 70px;
      height: 70px;
      margin-left: 2rem;
    }
    &:nth-child(5) {
      width: 50px;
      height: 50px;
      margin-left: 2rem;
    }
    &:nth-child(6) {
      width: 60px;
      height: 60px;
      margin-left: 2rem;
    }
    &:nth-child(7) {
      width: 65px;
      height: 65px;
      margin-left: 2rem;
    }
    @media screen and (min-width: 2000px) {
      &:nth-child(1) {
        width: 200px;
        height: 200px;
        margin-right: 2rem;
      }
      &:nth-child(2) {
        width: 180px;
        height: 180px;
        margin-left: 2rem;
      }
      &:nth-child(3) {
        width: 150px;
        height: 150px;
        margin-right: 3rem;
      }
      &:nth-child(4) {
        width: 130px;
        height: 130px;
        margin-left: 2rem;
      }
    }
  }
}
.app__header-img {
  width: 100%;
  position: relative;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  img {
    width: 100%;
    border-radius: 0px;
    object-fit: contain;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    z-index: 1;
    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }
  .overlay_circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 90%;
  }
}
.type::after {
  content: "|";
  animation: cursor 1.1s infinite step-start;
}

@keyframes cursor {
  50% {
    opacity: 0;
  }
}
