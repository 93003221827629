.app__testimonial{
    flex: 1;
    width: 100%;
    flex-direction: column;
}
.app__testimonial-item{
    width: 60%;
    height: 320px;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0 0 20px rgb(0, 0, 0, 0.2);
    transition: all 0.5s ease;
    p{  
        flex: 1;
        overflow-y: auto;
        font-size: 1.1rem;
        line-height: 2rem;
        color: black;
        font-family: var(--font-base);
        &::-webkit-scrollbar{
            width: 10px;
        }
        &::-webkit-scrollbar-track{
            background-color: rgb(230, 230, 230);
        }
        &::-webkit-scrollbar-thumb{
            background-color: rgb(155, 155, 155);

        }

    }
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
}
.app__testimonial-content{
    height: 50px;
    text-align: left;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    div{
        border: 2px solid rgb(245, 50, 157);
        padding: 3px;
        border-radius: 50%;
        margin-right: 10px;
        display: flex;
        align-items: center;
    }
    img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
    }
    span{
        h4{
            font-weight: 600;
            color: var(--secondary-color);
        }
        h5{
            font-weight: 400;
            color: var(--gray-color);
            margin-top: 5px;
        }
    }
}
.app__testimonial-btns{
    flex-direction: row;
    margin-top: 1rem;
    div{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: white;
        margin: 1rem;
        transition: all .3s ease-in-out;
        svg{
            width: 30px;
            height: 30px;
            color: var(--secondary-color);
        }
        &:hover{
            background-color: var(--secondary-color);
            svg{
                color: white;
            }
        }
    }
}
.app__testimonial-brands{
    width: 80%;
    flex-wrap: wrap;
    margin-top: 2rem;
    div{
        width: 120px;
        margin: 1.5rem;
        img{
            width: 100%;
            height: auto;
            object-fit: cover;
            filter: grayscale(1);
        }
        &:hover{
            img{
                filter: grayscale(0);
            }
        }
        @media screen and (min-width: 2000px) {
            width: 220px;
            margin: 2rem;
        }
        @media screen and (max-width: 450px) {
            width: 110px;
            margin: 1rem;
        }
    }
    @media screen and (max-width: 450px) {
        width: 100%;
    }
}