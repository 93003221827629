.app__works {
  flex: 1;
  width: 100%;
  flex-direction: column;
}
.app__work-filter {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  padding-top: 3.8rem;
  overflow-x: auto;
  position: sticky;
  padding-bottom: 5px;
  top: 0px;
  left: 0;
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: #edf2f8;
  @media screen and (max-width: 875px) {
    justify-content: flex-start;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  .item__active {
    background-color: var(--secondary-color) !important;
    color: white !important;
  }

  .app__work-filter-item {
    margin: 0px 10px;
    border: 1px solid var(--secondary-color);
    padding: 0.5rem 1rem;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    color: black;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.3rem 0.5rem;
    @media screen and (max-width: 875px) {
      min-width: fit-content;
      max-width: 20rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &:first-child {
      margin-left: 20px;
    }
    &:last-child {
      margin-right: 20px;
    }
    &:hover {
      background-color: var(--secondary-color);
      color: white;
    }
    @media screen and (min-width: 2000px) {
      padding: 1rem 2rem;
      border-radius: 0.85rem;
    }
  }
}
.app__work-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .app__work-item {
    height: 370px;
    width: 270px;
    flex-direction: column;
    margin: 1rem;
    padding: 0.7rem;
    border-radius: 0.5rem;
    color: black;
    cursor: pointer;
    background-color: white;
    transition: all 0.3s ease;
    &:hover {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    }
    @media screen and (min-width: 2000px) {
      width: 470px;
      padding: 1.25rem;
      border-radius: 0.75rem;
    }
    @media screen and (max-width: 300px) {
      width: 100%;
      margin: 1rem;
    }
  }
}
.app__work-img {
  width: 100%;
  height: 220px;
  position: relative;
  overflow: hidden;
  img {
    transition: all 0.3s ease-in-out;
    width: 100%;
    height: 220px;
    border-radius: 10px;
    object-fit: cover;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}
.app__work-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-family: var(--font-base);
    font-weight: 800;
    transition: all 0.2s ease-in-out;
    svg {
      width: 50%;
      height: 50%;
      color: white;
    }
  }
}
.app__work-content {
  padding: 10px;
  width: 100%;
  position: relative;
  flex-direction: column;
  h4 {
    margin: 20px;
    text-align: center;
    line-height: 1;
  }
  p {
    text-align: center;
  }
  .app__work-tag {
    position: absolute;
    padding: 0.5rem 1rem;
    background-color: white;
    top: -25px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid lightblue;
  }
}
.view__all {
  padding: 8px 12px;
  background-color: var(--secondary-color);
  border-radius: 10px;
  color: white;
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
  transition: all 0.3s;
  &:hover {
    background-color: rgb(255, 47, 47);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.35);
  }
}
