.app__skills{
    flex: 1;
    width: 100%;
    flex-direction: column;
}
.app__skills-container{
    width: 80%;
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 900px) {
        width: 100%;
        flex-direction: column;
    }
}
.app__skills-list{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 5rem;
    @media screen and (max-width: 900px) {
        margin-right: 0;
        justify-content: center;
        align-items: center;
    }
}
.app__skills-item{
    flex-direction: column;
    text-align: center;
    margin: 1rem;
    transition: all .4s ease-in-out;
    div{
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background-color: white;
      img{
          width: 50%;
          height: 50%;
      }
      &:hover{
          box-shadow: 0 0 25px #fef4f5;
      }
      @media screen and (min-width: 2000px) {
        width: 150px;
        height: 150px;
      }
    }
    p{
        font-weight: 500;
        margin-top: 0.5rem;
    }
    @media screen and (min-width: 2000px) {
        margin: 1rem 2rem;
        p{
            margin-top: 1rem;
        }
    }
}

.app__skills-exp{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    @media screen and (max-width: 900px) {
        margin: 2rem;
    }
}
.app__skills-exp-item{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1rem 0;
}
.app__skills-axp-works{
    flex: 1;
    .app__skills-exp-work{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1rem;
        cursor: pointer;
        h4{
            font-weight: 500;
        }
        p{
            font-weight: 400;
            margin-top: 5px;
            color: var(--gray-color);
        }
    }
}
.app__skills-exp-year{
    margin-right: 3rem;
    p{
        font-weight: 800;
        color: var(--secondary-color);
    }
    @media screen and (max-width: 450px) {
        margin-right: 1rem;
    }
}
.skills-tooltip{
    max-width: 360px !important;
}