.app__navbar {
  width: 100%;
  box-shadow: 0 0 15px rgba(168, 168, 168, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 2rem;
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  z-index: 2;
  position: fixed;
  top: 0;
  @media screen and (max-width: 600px) {
    padding: 0.2rem 1rem;
  }
  .app__navbar-logo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    user-select: none;
    .back {
      margin-right: 10px;
      svg {
        font-size: 30px;
        color: black;
      }
    }
    .border {
      display: flex;
      justify-content: center;
      padding: 2px;
      align-items: center;
      border-radius: 50%;
      border: 3px solid var(--secondary-color);
      img {
        border-radius: 50%;
        object-fit: cover;
        width: 35px;
        height: 35px;
      }
    }
    h3 {
      font-size: 22px;
      font-weight: 900;
      text-transform: uppercase;
      padding: 2px 6px;
      color: rgb(0, 0, 0);
      font-family: sans-serif;
      @media screen and (max-width: 600px) {
        font-size: 20px;
      }
      b {
        font-size: 28px;
        font-weight: 900;
        color: var(--secondary-color);
      }
      #b2 {
        color: rgb(243, 17, 194);
        font-size: 25px;
        font-weight: 900;
      }
    }
  }
  .app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    div {
      width: 7px;
      height: 7px;
      background: transparent;
      border-radius: 50%;
      margin-bottom: 5px;
    }
    a {
      text-decoration: none;
      margin: 0px 0.5rem;
      padding: 10px;
      color: var(--gray-color);
      flex-direction: column;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
      border-radius: 10px;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: var(--secondary-color);
        background-color: rgba(168, 168, 168, 0.18);
      }
    }
    li {
      flex-direction: column;
      &:hover {
        div {
          background: var(--secondary-color);
        }
      }
    }
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
}
.btn-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  @media (max-width: 640px) {
    gap: 0.6rem;
  }
}
.projects {
  .button {
    padding: 10px 20px;
    border-radius: 25px;
    text-decoration: none;
    color: rgb(17, 17, 17);
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid var(--secondary-color);
    transition: all 0.3s;
    &:hover {
      color: rgb(255, 255, 255);
      background: linear-gradient(
        to right,
        var(--secondary-color),
        rgb(243, 17, 194)
      );
      border: 2px solid white;
    }
    &:active {
      color: rgb(255, 255, 255);
      background: linear-gradient(
        to right,
        var(--secondary-color),
        rgb(177, 29, 147)
      );
      border: 2px solid white;
    }
  }
}
.projects-fill {
  .text {
    @media (max-width: 1168px) {
      display: none;
    }
  }
  svg {
    margin-right: 8px;
    @media (max-width: 1168px) {
      margin-right: 0px;
    }
  }

  .button {
    color: rgb(255, 255, 255);
    background: linear-gradient(
      to right,
      var(--secondary-color),
      var(--secondary-color)
    );
    padding: 12px 20px;
    border: none;
    min-width: max-content;
    @media (max-width: 1168px) {
      padding: 12px;
    }
    &:disabled {
      opacity: 0.7;
    }
    &:hover {
      color: rgb(255, 255, 255);
      background: linear-gradient(
        to right,
        var(--secondary-color),
        rgb(243, 17, 194)
      );
      border: none;
    }
    &:active {
      color: rgb(255, 255, 255);
      background: linear-gradient(
        to right,
        var(--secondary-color),
        rgb(177, 29, 147)
      );
      border: none;
    }
  }
}
.app__menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  background: var(--secondary-color);
  svg {
    cursor: pointer;
    width: 80%;
    height: 80%;
    color: white;
  }
  div {
    opacity: 0;
    width: 0%;
    transition: all 0.5s ease-in-out;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    padding: 1rem;
    height: 100vh;
    display: none;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    background: url("../../assets/bgWhite.png");
    background-color: white;
    background-size: cover;
    background-repeat: repeat;
    box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.18);
    &.mobile-menu {
      width: 80%;
      opacity: 1;
      z-index: 999;
      svg {
        transform: rotate(180deg);
      }
    }
    svg {
      width: 35px;
      height: 35px;
      transform: rotate(90deg);
      padding: 4px;
      border-radius: 50%;
      background-color: rgba(211, 211, 211, 0.473);
      color: var(--secondary-color);
      transition: all 0.4s ease;
    }
    ul {
      padding: 0;
      list-style: none;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      li {
        margin: 1rem;
        a {
          text-decoration: none;
          color: var(--gray-color);
          flex-direction: column;
          text-transform: uppercase;
          font-weight: 600;
          transition: all 0.3s ease-in-out;
          &:hover {
            color: var(--secondary-color);
          }
        }
      }
    }
    @media screen and (max-width: 900px) {
      display: flex;
    }
  }
  @media screen and (max-width: 900px) {
    display: flex;
  }
}
.search__bar {
  display: flex;
  height: 45px;
  width: 300px;
  padding: 0px 5px;
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.04);
  @media screen and (max-width: 500px) {
    width: auto;
    &.mobile__searchbar {
      background-color: rgb(248, 248, 248);
      position: fixed;
      top: 0;
      left: 0;
      height: 60px;
      width: 100%;
      border-radius: 0;
    }
  }
  svg {
    color: grey;
    font-size: 20px;
    align-self: center;
    margin-right: 8px;
    margin-left: 12px;
  }
  .input {
    font-family: var(--font-base);
    outline: none;
    border: none;
    background: none;
    font-size: 15px;
    flex: 1;
    @media screen and (max-width: 500px) {
      &.mobile__bar {
        display: inline;
        margin-left: 8px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    padding: 0px 15px;
    svg {
      margin: 0;
    }
    .input {
      display: none;
    }
  }
  &:focus-within {
    border: 1px solid gray;
    @media screen and (max-width: 500px) {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      border: none;
      background-color: white;
    }
  }
}
